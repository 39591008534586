import { Edit, useRecordContext, useTranslate } from "react-admin";
import { Company } from "../../../shared/types/types";
import DiagnosticForm from "./DiagnosticForm";

const DiagnosticEdit = () => (
    <Edit title={<CompanyTitle />} component="div">
        <DiagnosticForm edit/>
    </Edit>
);

const CompanyTitle = () => {
    console.log('Title....')
    const translate = useTranslate();
    const record = useRecordContext<Company>();
    return record ? (
        <span>
            {translate('resources.commands.title', {
                reference: record.name,
            })}
        </span>
    ) : null;
};

export default DiagnosticEdit;
