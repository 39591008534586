export default {
    BACKOFFICE_USER: [
        { id: "BACKOFFICE_USER_C", name: "Crear Usuarios" },
        { id: "BACKOFFICE_USER_R", name: "Leer Usuarios" },
        { id: "BACKOFFICE_USER_U", name: "Actualizar Usuarios" },
        { id: "BACKOFFICE_USER_D", name: "Borrar Usuarios" },
    ],
    BACKOFFICE_ROLE: [
        { id: "BACKOFFICE_ROLE_C", name: "Crear Roles" },
        { id: "BACKOFFICE_ROLE_R", name: "Leer Roles" },
        { id: "BACKOFFICE_ROLE_U", name: "Actualizar Roles" },
        { id: "BACKOFFICE_ROLE_D", name: "Borrar Roles" },
    ],
    BACKOFFICE_DIAGNOSTIC: [
        { id: "BACKOFFICE_DIAGNOSTIC_C", name: "Crear Diagnostico" },
        { id: "BACKOFFICE_DIAGNOSTIC_R", name: "Leer Diagnostico" },
        { id: "BACKOFFICE_DIAGNOSTIC_U", name: "Actualizar Diagnostico" },
        { id: "BACKOFFICE_DIAGNOSTIC_D", name: "Borrar Diagnostico" },
    ]
};

