import spanishMessages from "ra-language-spanish";
import { TranslationMessages } from "react-admin";

const customEnglishMessages: TranslationMessages = {
  ...spanishMessages,
  ra: {
    configurable: {
      customize: "Customize",
    },
    action: {
      add: "Añadir",
      add_filter: "Añadir filtro",
      back: "Ir atrás",
      bulk_actions:
        "1 item seleccionado |||| %{smart_count} items seleccionados",
      cancel: "Cancelar",
      clear_input_value: "Limpiar valor",
      clear_array_input: "Limpiar valores",
      clone: "Clonar",
      close: "Cerrar",
      close_menu: "Cerrar menú",
      confirm: "Confirmar",
      create: "Crear",
      delete: "Eliminar",
      edit: "Editar",
      expand: "Expandir",
      export: "Exportar",
      list: "Listar",
      open_menu: "Abrir menú",
      refresh: "Refrescar",
      remove: "Borrar",
      remove_filter: "Borrar filtro",
      save: "Guardar",
      search: "Buscar",
      show: "Mostrar",
      sort: "Ordenar",
      undo: "Deshacer",
      unselect: "Deseleccionar",
    },
    auth: {
      auth_check_error: "Por favor inicie sesión para continuar",
      logout: "Cerrar Sesión",
      password: "Contraseña",
      sign_in: "Acceder",
      sign_in_error: "La autenticación falló, por favor, vuelva a intentarlo",
      user_menu: "Perfil",
      username: "Usuario",
    },
    boolean: {
      true: "Sí",
      false: "No",
      null: " ",
    },
    input: {
      file: {
        upload_several:
          "Arrastre algunos archivos para subir o haga clic para seleccionarlos.",
        upload_single:
          "Arrastre un archivo para subir o haga clic para seleccionarlo.",
      },
      image: {
        upload_several:
          "Arrastre algunas imagénes para subir o haga clic para seleccionarlas.",
        upload_single:
          "Arrastre alguna imagen para subir o haga clic para seleccionarla.",
      },
      references: {
        all_missing: "No se pueden encontrar datos de referencias.",
        many_missing:
          "Al menos una de las referencias asociadas parece no estar disponible.",
        single_missing: "La referencia asociada no parece estar disponible.",
      },
      password: {
        toggle_visible: "Ocultar contraseña",
        toggle_hidden: "Mostrar contraseña",
      },
    },
    message: {
      about: "Acerca de",
      are_you_sure: "¿Está seguro?",
      bulk_delete_content:
        "¿Seguro que quiere eliminar este %{name}? |||| ¿Seguro que quiere eliminar estos %{smart_count} items?",
      bulk_delete_title:
        "Eliminar %{name} |||| Eliminar %{smart_count} %{name} items",
      delete_content: "¿Seguro que quiere eliminar este item?",
      delete_title: "Eliminar %{name} #%{id}",
      details: "Detalles",
      error:
        "Se produjo un error en el cliente y su solicitud no se pudo completar",
      invalid_form:
        "El formulario no es válido. Por favor verifique si hay errores",
      loading: "La página se está cargando, espere un momento por favor",
      no: "No",
      not_found:
        "O bien escribió una URL incorrecta o siguió un enlace incorrecto.",
      yes: "Sí",
      unsaved_changes:
        "Algunos de sus cambios no se guardaron. ¿Está seguro que quiere ignorarlos?",
      clear_array_input: "Limpiar valores",
      empty: "No hay registros disponibles",
    },
    navigation: {
      next: "Siguiente",
      no_more_results:
        "El número de página %{page} está fuera de los límites. Pruebe la página anterior.",
      no_results: "No se han encontrado resultados",
      page_out_from_begin: "No puede ir antes de la página 1",
      page_out_from_end: "No puede ir después de la última página",
      page_out_of_boundaries: "Número de página %{page} fuera de los límites",
      page_range_info: "%{offsetBegin} - %{offsetEnd} de %{total}",
      page_rows_per_page: "Filas por página:",
      prev: "Anterior",
      skip_nav: "Saltar al contenido",
    },
    sort: {
      sort_by: "Ordenar por %{field} %{order}",
      ASC: "ascendente",
      DESC: "descendente",
    },
    notification: {
      bad_item: "Elemento incorrecto",
      canceled: "Acción cancelada",
      created: "Elemento creado",
      data_provider_error:
        "Error del proveedor de datos. Consulte la consola para más detalles.",
      deleted: "Elemento borrado |||| %{smart_count} elementos borrados.",
      http_error: "Error de comunicación con el servidor",
      item_doesnt_exist: "El elemento no existe",
      logged_out: "Su sesión ha finalizado, vuelva a conectarse.",
      updated:
        "Elemento actualizado |||| %{smart_count} elementos actualizados",
      i18n_error:
        "No se pudieron cargar las traducciones para el idioma especificado",
    },
    page: {
      create: "Crear %{name}",
      dashboard: "Dashboard",
      edit: "%{name} #%{id}",
      empty: "Sin %{name} todavía.",
      error: "Algo salió mal",
      invite: "¿Quiere agregar uno/a?",
      list: "%{name}",
      loading: "Cargando",
      not_found: "No encontrado",
      show: "%{name} #%{id}",
    },
    validation: {
      email: "Debe ser un correo electrónico válido",
      maxLength: "Debe contener %{max} caracteres o menos",
      maxValue: "Debe ser %{max} o menos",
      minLength: "Debe contener %{min} caracteres al menos",
      minValue: "Debe ser al menos %{min}",
      number: "Debe ser un número",
      oneOf: "Debe ser uno de: %{options}",
      regex: "Debe coincidir con un formato específico (regexp): %{pattern}",
      required: "Requerido",
    },
  },

  resources: {
    menu: {
      configuration: {
        name: "Configuración",
      },
      my_perfil: {
        name: "Mi Perfil",
      },
      admin: {
        name: "Administración",
        user: {
          name: "Usuarios",
        },
        rol: {
          name: "Roles",
        },
        diagnostic: {
          name: "Diagnósticos"
        },
      },
      backoffice: {
        name: "Backoffice",
        user: {
          name: "Usuarios",
        },
        sampling_location: {
          name: "Lugar de Toma de Muestra",
        },
        rol: {
          name: "Roles",
        },
        diagnostic:{
          name: "Diagnósticos"
        },
      },
      forms: {
        name: "Operaciones",
        weight_report_operation: { name: "Reg. Exportación - Inf. Peso" },
        laboratory: { name: "Reg. Laboratorio" },
      },
    },
    auth: {
      rememberme: "Recordarme",
    },
    diagnostic: {
      title: "Diagnóstico",
      fields: {
        moodle: "ID Moodle",
        name: "Nombre",
        description: "Descripción",
        config: {
          send: "Configuración de Envio",
          hability: "Configuración de Habilidades"
        },
        student: "Estudiante",
        teacher: "Docente",
        scheduler: {
          name: "Programación",
          from: "Desde",
          to: "Hasta",
          teacher: "Docentes"
        }
      }
    },

    company: {
      name: "Compañía |||| Compañías",
      fields: {
        nro_fiscal: "Rut",
        name: "Nombre",
        phone: "Teléfono",
        email: "E-Mail",
        website: "Sitio web",
        direction: {
          name: "Dirección",
          state: "Región",
          city: "Ciudad",
          colony: "Comuna",
          street: "Calle",
        },
        contact: {
          name: "Contacto",
          area: "Área",
          email: "E-Mail",
          phone: "Teléfono",
        },
      },
    },
    sector: {
      name: "Rubro |||| Rubros",
      fields: {
        name: "Nombre",
      },
    },
    customer: {
      name: "Cliente |||| Clientes",
      fields: {
        nro_fiscal: "Rut",
        name: "Nombre",
        lastname: "Apellidos",
        phone: "Teléfono",
        mail: "E-Mail",
        birthday: "Fecha de Nacimiento",
        gender: "Género",
        civilStatus: "Estado Civil",
        educationalLevel: "Nivel de Educación",
        direction: {
          name: "Dirección",
        },
        card: {
          name: "Tarjeta Bancaria",
          type: "Tipo",
          issuer: "Emisor",
          bank: "Banco",
          titular: "Titular",
          ending: "Terminación",
          due: "Fecha de Vencimiento",
        },
      },
    },
    agent: {
      name: "Agente |||| Agentes",
      fields: {
        nro_fiscal: "Rut",
        name: "Nombre",
        lastname: "Apellidos",
        phone: "Teléfono",
        mail: "E-Mail",
        type: "Tipo",
        direction: {
          name: "Dirección",
          street: "Calle",
          colony: "Comuna",
          city: "Ciudad",
        },
      },
    },
    user: {
      name: "Usuario |||| Usuarios",
      title: "Usuario",
      fields: {
        nro_fiscal: "Rut",
        localization: "Localidad",
        name: "Nombre",
        lastname: "Apellidos",
        phone: "Teléfono",
        mail: "E-Mail",
        rol: "Rol",
        active: "Activo",
        repository: {
          name: "Repositorio de documentos",
          type: "Tipo",
          attachment: "Adjuntos",
        },
      },
    },
    rol: {
      name: "Rol |||| Roles",
      title: "Rol",
      permission: {
        name: "Permisos",
      },
      fields: {
        name: "Nombre",
        description: "Description",
        active: "Activo",
        permission: {
          name: "Permiso",
          module: "Modulo",
        },
      },
    },
    normative: {
      name: "Normativa |||| Normativas",
      title: "Normativa",
      structure: "Capítulos",
      fields: {
        code: "Código",
        name: "Nombre",
        description: "Description",
        version: "Version",
        active: "Activo",
        isrecord: "Permite Registros Operativos",
      },
    },

    localization: {
      name: "Localización |||| Localizaciones",
      title: "Localización",
      fields: {
        code: "Código",
        name: "Nombre",
        description: "Description",
        active: "Activo",
        user: "Usuario Responsable",
        phone: "Teléfono",
        direction: {
          name: "Dirección",
          street: "Calle",
          colony: "Comuna",
          city: "Ciudad",
        },
        normative: {
          name: "Normativas",
        },
      },
    },
    operation_registry: {
      name: "Registro de Operaciones",
      title: "Registro de Operaciones",
      fields: {
        verification_list: "Lista de Verificación",
        dus: "DUS",
        dus_date: "Fecha DUS",
        dus_items: "Cantidad de Ítems",
        dus_item_number: "N° de Ítem",
        custom_house: "Aduana",
        weight_report_number: "N° Inf. Peso",
        weight_report_date: "Fecha Inf. Peso",
        exporter: "Exportador",
        testing_lab_reception_date: "Fecha de Recepción en LE",
        motorship: "Motonave",
        sample_start_date: "Inicio de Muestreo",
        sample_end_date: "Término de Muestreo",
      },
    },
    laboratory_registry: {
      name: "Laboratorio || Laboratorios",
      title: "Registro de Op. de Laboratorio",
      fields: {
        oi_name: "Nombre del OI",
        element_sample: {
          metric: "Métrica",
          symbol: "Símbolo Químico",
          value: "Valor",
        },
        outsourced_analytes: "Analitos Externalizados",
        payable_element: "Elementos Pagables",
        penalizable_element: "Elementos Penalizables",
        outsourced_laboratory: "Laboratorio Subcontratado",
        quality_report: {
          name: "Informe de Calidad",
          fields: {
            file: "Archivo Infome de Calidad",
            number: "N° Informe de Calidad",
            date: "Fecha Infode de Calidad",
          },
        },
        active: "Activo",
      },
    },

    laboratory: {
      name: "Laboratorio |||| Laboratorios",
      title: "Laboratorio",
      fields: {
        name: "Nombre",
        current_resolution: "Resolución actual",
        accreditation_file: "Archivo de Acreditación",
        type: {
          name: "Tipo",
          internal: "Interno",
          outsourced: "Subcontratado",
        },
      },
    },
    instrument: {
      name: "Instrumento |||| Instrumentos",
      title: "Instrumento",
      fields: {
        name: "Nombre",
        code: "Código Interno",
        brand: "Marca",
        model: "Modelo",
        require_calibration: "Requiere de Calibración",
        active: "Activo",
        calibration: {
          date: "Fecha de Calibración",
          calibration_period: "Periodo de Calibración",
          verification_period: "Periodo de Verificación",
          calibrated_by: "Calibrado por",
          responsible: "Responsable de Calibración",
          localization: "Oficina",
        },
      },
    },
    port: {
      name: "Puerto |||| Puertos",
      title: "Puerto",
      fields: {
        name: "Nombre",
        active: "Activo",
      },
    },
    weight_report_operation: {
      name: "Registro de Exportación - Inf. Peso |||| Registro de Exportaciones - Inf. Peso",
      title: "Registro de Exportación - Inf. Peso",
      fields: {
        cochilco_contract: {
          number: "Número de Contrato COCHILCO",
          fee: "Cuota de Contrato COCHILCO",
        },
        commercial_contract: { number: "Número de Contrarto Comercial" },
        export_port: "Puerto de Exportación",
        destination_port: "Puerto de Destino",
        testing_lab: "Laboratorio de Ensayo",
        custom_lab_reception_date:
          "Fecha de Recepción en Laboratorio Químico de Aduanas",
        procedure_code: "Código y Versión del Procedimiento",
        weight_measuring_instrument: "Instrumendo de Medición de Peso",
        weight_sample_place: "Lugar de Toma de Muestra de Control de Peso",
        sampling_system: "Sistema de Muestreo",
        humidity_sample_place: "Lugar Det. Humedad y Preparación Muestra",
        boarding_start: "Inicio del Embarque",
        boarding_end: "Término del Embarque",
        gross_weight: "Peso Bruto en Kg por DUS",
        tare_weight: "Peso Tara en Kg por DUS",
        wet_net_weight: "Peso Neto Húmedo en Kg por DUS",
        dry_net_weight: "Peso Neto Seco en Kg por DUS",
        humidity_percent: "Porcentaje de Humedad por DUS",
        total_gross_weight: "Peso Bruto Total en Kg del DUS",
        weight_report: {
          number: "Número de Informe de Peso",
          date: "Fecha de Informe de Peso",
          file: "Archivo de Informe de Peso",
        },
        active: "Activo",
        stores: {
          name: "Bodegas",
          number: "Número de Bodega",
          wet_net_weight: "Peso Neto en Kg por Bodega",
        },
        verification_list: "Lista de Verificación",
        dus_copy: "Copia DUS",
        custom_delivery_letter: "Carta de Entrega Aduana",
        other_files: "Otros",
      },
    },
    sampling_location: {
      name: "Lugar de Toma de Muestra |||| Lugares de Toma de Muestra",
      title: "Lugar de Toma de Muestra",
      fields: {
        name: "Nombre",
        active: "Activo",
      },
    },
    commands: {
      title: "%{reference}",
    },
  },
};

export default customEnglishMessages;
