import {
    Admin,
    Resource,
    combineDataProviders,
    fetchUtils,
    CustomRoutes,
} from "react-admin";

import { Route } from "react-router-dom";

import { Login, Layout } from "./dcore/layout";

import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "./dcore/i18n/en";
import spanishMessages from "./dcore/i18n/es";
import authProvider from "./authProvider";
import buildRestProvider from "ra-data-simple-rest";
import { lightTheme } from "./dcore/layout/themes";
import { darkTheme } from "./dcore/layout/themes";
import perfil from "./dcore/lateral/perfil";
import rol from "./dcore/admin/rol";
import user from "./dcore/admin/user";
import DashboardList from "./dcore/dashboard/DashboardList";
import diagnostic from "./udla/diagnostic";
import AgentList from "./dcore/admin/user/UserList";


const fetchJson = (url, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    // add your own headers here
    options.headers.set("X-Correlation-Id", "foobar");
    options.headers.set("X-App-Id", "00e9396e-6c63-4834-8492-a1db4edc6421");
    options.headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("auth")?.toString()}`
    );
    return fetchUtils.fetchJson(url, options);
};

const backOfficeService = buildRestProvider(
    `${process.env.REACT_APP_API_URL}`,
    fetchJson
);

const dataProvider = combineDataProviders((resource): any => {
    console.log(`DATA PROVIDER => ${resource}`);
    switch (resource) {
        case "rol":
        case "user":
        case "diagnostic":
        case "dashboardFilters":
            return backOfficeService;
        default:
            throw new Error(`Unknown resource: ${resource}`);
    }
});

const i18nProvider = polyglotI18nProvider(
    (locale) => (locale === "es" ? spanishMessages : englishMessages),
    "es", // Default locale
    [
        { locale: "es", name: "Español", flag: "en" },
        { locale: "en", name: "English", flag: "en" },
    ]
);

const App = () => {
    return (
        <Admin
            title="Administrador"
            dataProvider={dataProvider}
            authProvider={authProvider}
            loginPage={Login}
            disableTelemetry
            layout={Layout}
            i18nProvider={i18nProvider}
            requireAuth
            theme={lightTheme}
        >
            <Resource name="rol" {...rol} />
            <Resource name="user" {...user} />
            <Resource name="diagnostic" {...diagnostic} />
        </Admin>
    );
};

export default App;
