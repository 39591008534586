import { Datagrid, Loading, RaRecord, TextField, useListContext, useTranslate, BooleanField } from "react-admin";

import { Sector } from "../../../../shared/types/types";
import CoreEditButton from "../../../../shared/components/core/CoreEditButton";

interface MobileGridProps {
    data?: RaRecord[];
}

const MobileGrid = (props: MobileGridProps) => {

    const { data, isLoading } = useListContext<Sector>();
    const translate = useTranslate();
    if (isLoading || data.length === 0) 
    {
        return <Loading />; 
    }
    return (
        <Datagrid bulkActionButtons={false}>
            <TextField source="name" variant="body1" />
            <BooleanField source="active" />
            <CoreEditButton permission="BACKOFFICE_ROLE_U" />
            
        </Datagrid>

    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;