import { BooleanField, Datagrid, DateField, Loading, RaRecord, TextField, useListContext, useTranslate } from "react-admin";
import { Sector } from "../../../shared/types/types";
import CoreEditButton from "../../../shared/components/core/CoreEditButton";
import CoreDeleteButton from "../../../shared/components/core/CoreDeleteButton";

interface MobileGridProps {
    data?: RaRecord[];
}

const MobileGrid = (props: MobileGridProps) => {

    const { data, isLoading } = useListContext<Sector>();
    const translate = useTranslate();
    if (isLoading || data.length === 0) 
    {
        return <Loading />; 
    }
    return (
        <Datagrid bulkActionButtons={false}>
            <TextField source="idMoodle" variant="body1" />
            <TextField source="name" variant="body1" />
            <CoreEditButton permission="BACKOFFICE_DIAGNOSTIC_U" />
            <CoreDeleteButton permission="BACKOFFICE_DIAGNOSTIC_D" />
        </Datagrid>

    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
