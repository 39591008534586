import axios from "axios";

const fetchDataService = () => {
  const urlBase = process.env.REACT_APP_API_BASE_URL;
  const withoutHeaderAuthN = {
    "X-Correlation-Id": "64008734.04828116",
    "X-App-Id": "00e9396e-6c63-4834-8492-a1db4edc6421",
    "Content-Type": "application/json",
  };

  const headerAuthN = {
    Authorization: `Bearer ${localStorage.getItem("auth")?.toString()}`,
    "X-Correlation-Id": "64008734.04828116",
    "X-App-Id": "00e9396e-6c63-4834-8492-a1db4edc6421",
    "Content-Type": "application/json",
  };

  const postData = (url: string, data: any, headers: any): Promise<any> => {
    if (headers == null) {
      return axios.post(url, data, { headers: withoutHeaderAuthN });
    }
    return axios.post(url, data, { headers: headers });
  };

  const putData = (url: string, data: any, headers: any): Promise<any> => {
    if (headers == null) {
      return axios.put(url, data, { headers: withoutHeaderAuthN });
    }
    return axios.put(url, data, { headers: headers });
  };

  const getData = (url: string): Promise<any> => {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("auth")?.toString()}`,
      "X-Correlation-Id": "64008734.04828116",
      "X-App-Id": "00e9396e-6c63-4834-8492-a1db4edc6421",
      "Content-Type": "application/json",
    };
    return axios.get(url, { headers: header });
  };

  /** AUTHN */
  const postLoginUser = (data: any): Promise<any> => {
    return postData(`${urlBase}/api/v1.0/login`, data, null);
  };

  const getPermissionsUser = (): Promise<any> => {
    return getData(`${urlBase}/api/v1.0/claims`);
  };

  /**TEMPLATE*/
  const getTemplateData = (
    urlParams: string,
    data: any = null
  ): Promise<any> => {
    return getData(`${urlBase}/api/v1/template/${urlParams}`);
  };

  /**COMPANY*/
  const getCompanyData = (): Promise<any> => {
    return getData(`${urlBase}/api/v1.0/company`);
  };

  const getNormativeData = (normativeId: string): Promise<any> => {
    return getData(`${urlBase}/api/v1.0/normative/${normativeId}`);
  };

  const getSectorData = (): Promise<any> => {
    return getData(`${urlBase}/api/v1.0/sector`);
  };

  const postChapterTreeData = (chapterTreeId: string, data: any): Promise<any> => {
    return postData(`${urlBase}/api/v1.0/chapterTree/${chapterTreeId}`, data, headerAuthN);
  };

  const putChapterTreeData = (chapterTreeId: string, data: any): Promise<any> => {
    return putData(`${urlBase}/api/v1.0/chapterTree/${chapterTreeId}`, data, headerAuthN);
  };

  const deleteChapterTreeData = (chapterTreeId: string, data: any): Promise<any> => {
    return axios.delete(`${urlBase}/api/v1.0/chapterTree/${chapterTreeId}`, {
      data,
      headers: headerAuthN,
    });
  };

  return {
    postLoginUser,
    getPermissionsUser,
    getTemplateData,
    getCompanyData,
    getSectorData,
    postChapterTreeData,
    putChapterTreeData,
    deleteChapterTreeData,
    getNormativeData
  };
};

export default fetchDataService;
