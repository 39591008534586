import {
  BooleanInput,
  Loading,
  SelectInput,
  SimpleForm,
  TextInput,
  email,
  number,
  required,
  useGetList,
} from "react-admin";

import PhoneInput from "../../../../shared/components/phoneInput/PhoneInput";

import {
  Box,
  InputAdornment,
  Typography,
  Toolbar as MuiToolbar,
  Stack,
} from "@mui/material";
import {
  MailOutlineSharp,
  PhoneOutlined,
} from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";

interface UserFormProps {
  formTitle: string;
  edit?: boolean;
}

const UserForm = ({ formTitle, edit }: UserFormProps) => {
  const {
    data: rolData,
    total: rolTotal,
    isLoading: rolIsLoading,
    error: rolError,
  } = useGetList("rol", {
    filter: { active: true },
    pagination: { page: 1, perPage: 10 },
    sort: { field: "name", order: "DESC" },
  });

  if (rolIsLoading || rolData?.length === 0) {
    return <Loading />;
  }

  return (
    <SimpleForm
      toolbar={<FormToolbar edit={edit ? true : undefined} />}
      sx={{ mt: 3, mb: 3 }}
    >
      <Grid width="70%" alignSelf="center">
        <Stack display="flex" justifyContent="center" width="100%" spacing={3}>
          <Typography variant="h6">{edit ? 'EDITAR USUARIO' : 'CREAR USUARIO'}</Typography>
          <Stack direction="row" spacing={2}>
            <TextInput
              fullWidth
              variant="outlined"
              helperText={false}
              source="name"
              label="resources.user.fields.name"
              validate={required()}
            />
            <TextInput
              fullWidth
              variant="outlined"
              source="lastname"
              helperText={false}
              label="resources.user.fields.lastname"
              validate={required()}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextInput
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineSharp />
                  </InputAdornment>
                ),
              }}
              type="email"
              source="mail"
              label="resources.user.fields.mail"
              fullWidth
              helperText={false}
              validate={[required(), email()]}
            />
            <SelectInput
              source="rol.id"
              variant="outlined"
              label="resources.user.fields.rol"
              fullWidth
              helperText={false}
              validate={required()}
              choices={rolData}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextInput
              helperText={false}
              variant="outlined"
              source="nrofiscal"
              label="resources.user.fields.nro_fiscal"
              fullWidth
              validate={[
                required(),
                number("Ingrese RUT sin dígito verificador"),
              ]}
            />
            <BooleanInput
              sx={{ ml: 1 }}
              helperText={false}
              label="resources.user.fields.active"
              source="active"
              fullWidth
            />
          </Stack>
          {edit ?
            <>
              <Typography variant="subtitle2" >
                Presione el siguiente botón para restablecer la contraseña del usuario, y luego presione guardar:
              </Typography>
              <BooleanInput
                defaultValue={false}
                sx={{ ml: 1 }}
                helperText={false}
                label="Restablecer contraseña"
                source="resetPassword"
                fullWidth
              />
              <Typography variant="subtitle2" >
                La contraseña del usuario será el nombre + la primera letra del apellido. Por ejemplo: Nombre: John, Apellido: Doe, Contraseña: JohnD
              </Typography>
            </>
            :
              <Typography variant="subtitle2" >
                La contraseña inicial del usuario será el nombre + la primera letra del apellido. Por ejemplo: Nombre: John, Apellido: Doe, Contraseña: JohnD
              </Typography>
          }
          
        </Stack>
      </Grid>
    </SimpleForm>
  );
};

export default UserForm;
