import React, { useState } from 'react';
import { Datagrid, TextField, useRecordContext } from 'react-admin';
import { Box, Pagination } from '@mui/material';

const ArrayPaginationField = ({ source, perPage }) => {
    const record = useRecordContext();
    const [page, setPage] = useState(1);
    const array = record[source] || [];
    const pageCount = Math.ceil(array.length / perPage);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const paginatedArray = array.slice((page - 1) * perPage, page * perPage);

    return (
        <>
            <Datagrid data={paginatedArray} bulkActionButtons={false} rowSx={(record, index) => ({height: '50px'})}>
                <TextField label="N. pregunta" source="questionNum" sortable={false}/>
                <TextField label="Id pregunta" source="questionId" sortable={false}/>
                <TextField label="Eje curricular" source="curricularAxis" sortable={false}/>
                <TextField label="Habilidad" source="ability" sortable={false}/>
                <TextField label="Resultados de aprendizaje" source="learningResults" sortable={false}/>
            </Datagrid>
            <Box display="flex" justifyContent="flex-end" m={1}>
                {pageCount > 1 && (
                    <Pagination
                        count={pageCount}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                    />
                )}
            </Box>
            
        </>
    );
};

export default ArrayPaginationField;
