import { EditButton } from "react-admin";
import { BtnProps } from "./BtnProps";
import validatePermissions from "../validatePermissions";

const CoreEditButton = (props: BtnProps) => {
    const isValid = validatePermissions(props.permission)
    return (
        isValid ? <EditButton {...props} /> : null
    );
}

export default CoreEditButton;

