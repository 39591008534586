import { Edit, useRecordContext, useTranslate } from "react-admin";
import { Customer } from "../../../../shared/types/types";
import UserForm from "./UserForm";

const CompanyEdit = () => (
    <Edit title={<CustomerTitle />} component="div">
        <UserForm formTitle={"Eitar Usuario"} edit={true}/>
    </Edit>
);

const CustomerTitle = () => {
    console.log('Title....')
    const translate = useTranslate();
    const record = useRecordContext<Customer>();
    return record ? (
        <span>
            {translate('resources.commands.title', {
                reference: record.name,
            })}
        </span>
    ) : null;
};


export default CompanyEdit;
