import {
    ArrayField,
    BooleanInput,
    BulkUpdateButton,
    Button,
    CreateButton,
    Datagrid,
    DateInput,
    DateTimeInput,
    DeleteButton,
    Form,
    FormDataConsumer,
    Labeled,
    List,
    Loading,
    NumberInput,
    ReferenceField,
    SaveButton,
    Show,
    SimpleShowLayout,
    TabbedForm,
    TextField,
    TextInput,
    Toolbar,
    required,
    useCreate,
    useNotify,
    useRecordContext,
    useRefresh,
} from "react-admin";
import { Abilities, Diagnostic } from "../../../shared/types/types";
import { Grid, Box, Card, Stack, Container, Table, TableHead, TableCell, TableRow, TableBody } from "@mui/material";
import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';

import BarTreeView from "../../../shared/components/treeview/BarTreeView";
import { Fragment, useEffect, useState } from "react";
import { FileInput, FileField } from 'react-admin';
import { WidthFull } from "@mui/icons-material";
import AbilitiesView from "./AbilitiesView";

const DiagnosticForm = ({ edit = false }) => {
    const record = useRecordContext<Diagnostic>();
    const [isLoading, setIsLoading] = useState(false);
    const [abilities, setAbilities] = useState<Abilities[]>([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const notify = useNotify();
    const refresh = useRefresh();

    console.log(JSON.stringify(record));

    const handleFileChange = (file) => {
        setSelectedFile(file);
    };

    const uploadFile = () => {
        setIsLoading(true);
        const formData = new FormData();

        if (selectedFile === null) {
            console.log('no file');
            return;
        }

        formData.append('file', selectedFile);
        formData.append('quizId', record.idMoodle);
        
        fetch(`${process.env.REACT_APP_API_URL}/abilities`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("auth")?.toString()}`
            },
            body: formData,
        })
        .then(response => {
            if (!response.ok) {
                setIsLoading(false);
                notify('Error al cargar las habilidades', { type: 'error' })
                throw new Error('Error al subir el archivo');
            }
            return response.json();
        })
        .then(data => {
            console.log(`DATA! => ${JSON.stringify(data)}`);
            notify('Habilidades cargadas correctamente', { type: 'success' })
            refresh();
            setAbilities(data);
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error al subir el archivo:', error);
            setIsLoading(false);
        });
    }

    const formView = () => {
        return (
            <Form onSubmit={uploadFile}>
                <FormDataConsumer>
                    {({ getSource }) => (
                        <FileInput source={getSource?.('attachment') || ""} accept="text/csv" onChange={handleFileChange}>
                            <FileField source="src" title="title" />
                        </FileInput>
                    )}
                </FormDataConsumer>
                <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <SaveButton onClick={uploadFile} alwaysEnable/>
                </Container>
            </Form>
        )
    }

    const dateValidator = (value, values) => {
        const startDate = new Date(values.scheduler.initDate);
        const endDate = new Date(values.scheduler.endDate);
    
        if (startDate > endDate) {
            return 'La fecha de inicio no puede ser posterior a la fecha de fin.';
        }
    
        return null;
    };

    const numberValidator = (value) => {

    }

    return (
        <TabbedForm
            toolbar={false}
            defaultValues={{ id: record ? record.id : null }}
        >
            <TabbedForm.Tab label="resources.diagnostic.title">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <h2>Configuración general</h2>
                        <TextInput
                            autoFocus
                            source="name"
                            label="resources.diagnostic.fields.name"
                            fullWidth
                            validate={required()}
                        />

                        <TextInput
                            autoFocus
                            source="idMoodle"
                            label="resources.diagnostic.fields.moodle"
                            fullWidth
                            validate={required()}
                        />

                        <TextInput
                            autoFocus
                            source="description"
                            label="resources.diagnostic.fields.description"
                            fullWidth
                            validate={required()}
                        />
                        <Toolbar>
                            <SaveButton />
                            <DeleteButton />
                        </Toolbar>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>

            {edit &&
                <TabbedForm.Tab label="Estudiante">
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <h2>Configuración reporte estudiante</h2>
                            <RichTextInput source="body" label="Texto encabezado reporte"
                                toolbar={<RichTextInputToolbar size="large"/> } />
                            <RichTextInput source="achievedText" label="Texto logrado" defaultValue='<strong>###PORCENTAJE_DE_LOGRO###</strong>'
                                toolbar={<RichTextInputToolbar size="large"/> } />
                            <RichTextInput source="notAchievedText" label="Texto no logrado" defaultValue='<strong>###PORCENTAJE_DE_LOGRO###</strong>'
                                toolbar={<RichTextInputToolbar size="large"/> } />
                            <h5>NOTA: Por favor, no modificar ni borrar el texto ###PORCENTAJE_DE_LOGRO###, ya que será reemplazado automáticamente por el porcentaje de logro alcanzado por el estudiante.</h5>
                            <Toolbar>
                                <SaveButton />
                                <DeleteButton />
                            </Toolbar>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
            }

            {edit &&
                <TabbedForm.Tab label="Docente">
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <h2>Configuración reporte docente</h2>
                            <RichTextInput source="teacherHeaderText" label="Texto encabezado reporte"
                                toolbar={<RichTextInputToolbar size="large"/> } />
                            <RichTextInput source="teacherFooterText" label="Texto pie reporte"
                                toolbar={<RichTextInputToolbar size="large"/> } />
                            <Grid item xs={6}>
                                <h2>Configuración envío reporte</h2>
                                <DateInput label="Fecha de inicio:" source="scheduler.initDate" validate={dateValidator}/>
                                <DateInput label="Fecha de fin:" source="scheduler.endDate" validate={dateValidator}/>
                                <NumberInput label="Frecuencia (días):" min={1} max={30} source="scheduler.frequencyDays"/>
                            </Grid>
                            <Toolbar>
                                <SaveButton />
                                <DeleteButton />
                            </Toolbar>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
            }

            {/*record && (
                <TabbedForm.Tab label="resources.diagnostic.fields.config.send">
                    <Grid container spacing={2}>
                        <TextInput
                            autoFocus
                            source="name"
                            label="resources.diagnostic.fields.name"
                            fullWidth
                            validate={required()}
                        />

                        <TextInput
                            autoFocus
                            source="idMoodle"
                            label="resources.diagnostic.fields.moodle"
                            fullWidth
                            validate={required()}
                        />
                        <BooleanInput label="resources.diagnostic.fields.student" source="is_student" />
                        <BooleanInput label="resources.diagnostic.fields.teacher" source="is_teacher" />
                        <Show>
                            <SimpleShowLayout>
                                <TextField label="resources.diagnostic.fields.scheduler.name" />
                                <DateTimeInput source="scheduler_from" label="resources.diagnostic.fields.scheduler.from" />
                                <DateTimeInput source="scheduler_from" label="resources.diagnostic.fields.scheduler.to" />
                            </SimpleShowLayout>
                        </Show>
                        <Show>
                            <SimpleShowLayout>
                                <TextField label="resources.diagnostic.fields.scheduler.teacher" />
                            </SimpleShowLayout>
                        </Show>
                    </Grid>
                </TabbedForm.Tab>
            )*/}
            {edit ? 
                <TabbedForm.Tab label="resources.diagnostic.fields.config.hability">
                    <Grid width="90%" alignSelf="center">
                        {isLoading ?
                            <Loading/>
                            :
                            <AbilitiesView/>
                        }
                        <Form onSubmit={uploadFile}>
                            <FormDataConsumer>
                                {({ getSource }) => (
                                    <FileInput source={getSource?.('attachment') || ""} accept="text/csv" onChange={handleFileChange}>
                                        <FileField source="src" title="title" />
                                    </FileInput>
                                )}
                            </FormDataConsumer>
                            <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <SaveButton onClick={uploadFile}/>
                            </Container>
                        </Form>
                    </Grid>
                </TabbedForm.Tab>
                : null
            }
            {/*record && (
                <TabbedForm.Tab label="resources.diagnostic.fields.config.hability">
                    <>
                        {abilities && abilities.length > 0
                            ?   
                                <>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>N. pregunta</TableCell>
                                                <TableCell>Id pregunta</TableCell>
                                                <TableCell>Eje curricular</TableCell>
                                                <TableCell>Habilidad</TableCell>
                                                <TableCell>Resultados de aprendizaje</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {abilities.map(ability => {
                                                return (
                                                    <TableRow key={ability.id}>
                                                        <TableCell>{ability.questionNum}</TableCell>
                                                        <TableCell>{ability.questionId}</TableCell>
                                                        <TableCell>{ability.curricularAxis}</TableCell>
                                                        <TableCell>{ability.ability}</TableCell>
                                                        <TableCell>{ability.learningResults}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                    <h1>
                                        Para crear habilidades cargue un archivo CSV.
                                    </h1>
                                    <Form onSubmit={uploadFile}>
                                        <FormDataConsumer>
                                            {({ getSource }) => (
                                                <FileInput source={getSource?.('attachment') || ""} accept="text/csv" onChange={handleFileChange}>
                                                    <FileField source="src" title="title" />
                                                </FileInput>
                                            )}
                                        </FormDataConsumer>
                                        <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <SaveButton onClick={uploadFile}/>
                                        </Container>
                                    </Form>
                                </>
                            :   
                                <>
                                    <h1>
                                        No existen habilidades configuradas. Por favor cargue un archivo CSV con las habilidades.
                                    </h1>
                                    <Form onSubmit={uploadFile}>
                                        <FormDataConsumer>
                                            {({ getSource }) => (
                                                <FileInput source={getSource?.('attachment') || ""} accept="text/csv" onChange={handleFileChange}>
                                                    <FileField source="src" title="title" />
                                                </FileInput>
                                            )}
                                        </FormDataConsumer>
                                        <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <SaveButton onClick={uploadFile}/>
                                        </Container>
                                    </Form>
                                </>
                                
                        }
                    </>
                </TabbedForm.Tab>
                    )*/}
        </TabbedForm>
    );
};

export default DiagnosticForm;
