import { Container, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Abilities, Diagnostic } from "../../../shared/types/types";
import { ArrayField, Datagrid, List, TextField, useRecordContext } from "react-admin";
import ArrayPaginationField from "./ArrayPaginationField";

//https://marmelab.com/react-admin/Fields.html#deep-field-source
const AbilitiesView = () => {
    const record = useRecordContext<Diagnostic>();

    return (
        <>
            {record && record.abilitiesList && record.abilitiesList.length > 0 ?
                <div>
                    <h2>Habilidades actualmente configuradas</h2>
                    <List actions={false} disableSyncWithLocation pagination={false}>
                        <ArrayPaginationField source="abilitiesList" perPage={10} />
                    </List>
                    <h2>Para modificar las habilidades, por favor cargue un archivo CSV con las habilidades.</h2>
                </div>
                :
                <div>
                    <h1>
                        No existen habilidades configuradas. Por favor cargue un archivo CSV con las habilidades.
                    </h1>
                </div>
            }
        </>
    )
}

export default AbilitiesView;