import ProductIcon from '@mui/icons-material/Collections';
import DiagnosticList from './DiagnosticList';
import DiagnosticCreate from './DiagnosticCreate';
import DiagnosticEdit from './DiagnosticEdit';

export default {
    list: DiagnosticList,
    create: DiagnosticCreate,
    edit: DiagnosticEdit,
    icon: ProductIcon
};
