import {  BooleanField, Datagrid, EmailField, Loading, RaRecord, TextField, useListContext, useTranslate } from "react-admin";
import { User } from "../../../../shared/types/types";
import CoreEditButton from "../../../../shared/components/core/CoreEditButton";
import CoreDeleteButton from "../../../../shared/components/core/CoreDeleteButton";

interface MobileGridProps {
    data?: RaRecord[];
}

const MobileGrid = (props: MobileGridProps) => {

    const { data, isLoading } = useListContext<User>();
    const translate = useTranslate();
    console.log(data)
    if (isLoading || data.length === 0) 
    {
        return <Loading />; 
    }

    return (
        <Datagrid bulkActionButtons={false}>
            <TextField source="name" variant="body1"/>
            <TextField source="lastname" variant="body1"/>
            <EmailField source="mail" />
            <TextField source="rol.name" sortable={false}/>
            <BooleanField source="active" />
            <CoreEditButton permission="BACKOFFICE_USER_U" />
            <CoreDeleteButton permission="BACKOFFICE_USER_D" />
        </Datagrid>

    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;