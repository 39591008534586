import * as React from 'react';
import {
    AppBar,
    TitlePortal,
    Logout,
    UserMenu,
    useTranslate,
    useUserMenu
} from 'react-admin';
import { Link } from 'react-router-dom';
import {
    Box,
    MenuItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    Theme,
    Typography
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

//import Logo from './Logo';
import logo from './logo_light.svg';

const ConfigurationMenu = React.forwardRef((props, ref) => {
    const translate = useTranslate();
    const { onClose } = useUserMenu();

    return (
        <MenuItem component={Link}
            // @ts-ignore
            ref={ref} {...props} to="/perfil" onClick={onClose}>
            
            <ListItemIcon>
                <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{translate('resources.menu.my_perfil.name')}</ListItemText>
        </MenuItem>
    );
});

const CustomUserMenu = () => (
    <UserMenu>
        {/*<ConfigurationMenu />*/}
        <Logout />
    </UserMenu>
);

const CustomAppBar = () => {
    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );
    return (
      <AppBar elevation={1} userMenu={<CustomUserMenu />}>
            <Typography
                flex="1"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                variant="h6"
                id="react-admin-title"
                sx={{color: "primary.contrastText"}}
            />
            <img src={logo} style={{width: "180px"}} alt="udla-logo"/>
            {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
        </AppBar>
    );
};

// {isLargeEnough && <Logo />} add line 57

export default CustomAppBar;
