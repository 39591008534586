import { AuthProvider } from 'react-admin';
import fetchDataService from "../shared/utilities/fetchs";
import { getAvatar, getFullName, getUserId } from '../shared/utilities/local-storage-manager';

const { postLoginUser, getPermissionsUser } = fetchDataService();

interface Credentials {
    username: String;
    password: String;
}

let permissions; // memory cache
let permissionsExpiresAt = 10;

const getPermissionsForFrontend = () => {
    const request = getPermissionsUser();
    return request.then(response => {
        console.log(response.data.data)
        localStorage.setItem('permissions', JSON.stringify(response.data.data));
        permissions = response.data.data
        permissionsExpiresAt = Date.now() + 1000 * 60 * permissionsExpiresAt; // 5 minutes
        return permissions;
    })
}


const authProvider: AuthProvider = {
    login: ({ username, password }) => {
        let credentials: Credentials = {
            username: username,
            password: password
        }
        const token: any = postLoginUser(credentials);

        return token
            .then(response => {
                if (response.status == 200) {
                    return response.data;
                }
            })
            .then(auth => {
                localStorage.setItem('auth', auth.data.accessToken);
            })
            .catch((error) => {
                console.log(error.response.data.message);
                throw new Error(error.response.data.data.message);
            })
            ;
    },
    logout: () => {
        localStorage.removeItem('auth');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
    checkError: (error) => {
        console.log('Check Error: ', error)
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        return Promise.resolve()
    },
    checkAuth: () =>
        localStorage.getItem('auth') ? Promise.resolve() : Promise.reject({ message: 'login.required' }),
    getPermissions: () => {
        //return Date.now() > permissionsExpiresAt ? getPermissionsForFrontend() : permissions;
        return getPermissionsForFrontend();
    },
    getIdentity: () => {

        const company = getFullName();
        const avatar = getAvatar();
        const userId = getUserId();
        console.log('User ID ', userId)
        console.log('Company ', company)
        console.log('Avatar ', avatar)
        return Promise.resolve({
            id: userId,
            fullName: company,
            avatar: avatar,
        })
    }
    ,
};

export default authProvider;
