import { useState } from 'react';
import Box from '@mui/material/Box';

import { useTranslate, DashboardMenuItem, MenuItemLink, MenuProps, useSidebarState } from 'react-admin';

import SubMenu from './SubMenu';

import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ScienceIcon from '@mui/icons-material/Science';
import BiotechIcon from '@mui/icons-material/Biotech';
import { AccountTree, AdminPanelSettings, Apartment, ArrowOutward, DirectionsBoat, Handyman, LocationOn, Warehouse } from '@mui/icons-material';

type MenuName = 'menuBackoffice' | 'menuServices';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuBackoffice: true,
        menuServices: false
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            {/*<DashboardMenuItem
              sx={{'&:hover':{backgroundColor: 'secondary.main', color: 'primary.contrastText'}}}
            />*/}

            <SubMenu
                handleToggle={() => handleToggle('menuBackoffice')}
                isOpen={state.menuBackoffice}
                name="resources.menu.admin.name"
                icon={<BusinessIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/user"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.menu.admin.user.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<SupportAgentIcon />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/rol"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.menu.admin.rol.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<AdminPanelSettings />}
                    dense={dense}
                />

            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle('menuBackoffice')}
                isOpen={state.menuBackoffice}
                name="resources.menu.backoffice.name"
                icon={<BusinessIcon />}
                dense={dense}
            >
                

                <MenuItemLink
                    to="/diagnostic"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.menu.backoffice.diagnostic.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<Apartment />}
                    dense={dense}
                />

            </SubMenu>
        </Box>
    );
};

export default Menu;
